<template>
<div>
    <div class="alert alert-danger" v-if="this.error">
      {{this.error}}
    </div>
   <b-form @submit.prevent="login()">
      <b-row class="justify-content-center">
        <b-col cols="12">
            <b-form-group>
              <b-form-input
                id="username"
                v-model="form.username"
                required
                placeholder="Username"
              ></b-form-input>
            </b-form-group>
        </b-col>
        <b-col cols="12">
            <b-form-group>
              <b-form-input
                id="password"
                v-model="form.password"
                type="password"
                required
                placeholder="•••••••••••"
              ></b-form-input>
            </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
            <b-button type="submit" variant="primary" class="text-white mb-2" size="lg" block :disabled="this.$store.state.status === 'loading'">
              <span v-if="this.$store.state.status !== 'loading'">Login</span>
              <span v-else><Loader/></span>
            </b-button>
        </b-col>
        <!-- <b-col class="text-right"><a href="#"><small>Forgot your password?</small></a></b-col> -->
      </b-row>
   </b-form>
</div>
</template>
<script>
import Loader from '@/components/Loader'
export default {
  components: {
    Loader
  },
  data(){
    return {
      form: {
        username: '',
        password: ''
      },
      error: null,
    }
  },
  computed: {
  },
  methods: {
    login() {
      this.form.username = this.form.username.toLowerCase();

      // try {
      //   this.$store.dispatch('login', this.form).catch(err => console.log(err))
      //   console.log('should work')
      // } catch(e) {
      //   console.log('didnt work')
      //   console.log(e)
      // }

      this.$store.dispatch('login', this.form)
      .then(
        () => this.$router.go('/')
      )
      .catch(err => {
          if(err.message == 'Request failed with status code 403'){
            this.error = 'Invalid username or password. Please try again.'
          } else {
            this.error = err
          }
      })
    }
  },
  mounted () {
  }
}
</script>