<template>
  <div class="login">
    <HeroContainer :hideProfile="true">
      <img src="@/assets/images/grdc-logo.jpg" srcset="@/assets/images/grdc-logo.jpg 1x, @/assets/images/grdc-logo@2x.jpg 2x" alt="GRDC - National Variety Trials" class="img-fluid">
    </HeroContainer>
    <b-container class="mt-5">
      <b-row class="justify-content-center">
        <b-col cols="12" lg=6>
          <h3 class="text-center mb-3">Login to your account</h3>
          <LoginForm />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import HeroContainer from '@/components/HeroContainer'
import LoginForm from '@/components/LoginForm'

export default {
  name: 'Login',
  components: {
    HeroContainer,
    LoginForm
  },
  // data () {
  //   return {
  //   }
  // },
  mounted () {
     if(this.$store.state.user !== null) {
       this.$router.push('/')
     }
  }
}
</script>
